// TODO: title styles should be a part of Dialog component and reused appropriately
.ChooseStoreModal-title {
  display: flex;
  flex-direction: row;
  background-color: #9b9b9b;
  color: $black;
  padding: 10px 0px;
  text-transform: uppercase;
}

.ChooseStoreModal-title-text {
  justify-content: left;
  flex-grow: 1;
  font-family: "AutoZone Medium";
  padding-left: 16px;
  font-size: 16px;
  font-weight: 900;
  font-style: normal;
  font-stretch: condensed;
}

.ChooseStoreModal-selected-store {
  font-size: 18px;
  font-weight: bold;
  color: $success;
}

.ChooseStoreModal-close-icon {
  margin: 2px 14px 2px 0px;
  font-size: 12px;
  cursor: pointer;
}

.ChooseStoreModal-body {
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  color: $black;
  font-size: 14px;
}

.ChooseStoreModal-error {
  margin-top: 10px;

  #errorMessageWrapper {
    float: none;
  }
}

.ChooseStoreModal-action-buttons {
  width: 100%;
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.ChooseStoreModal-action-buttons-divider {
  width: 16px;
}

.ChooseStoreModal-action-button {
  flex: 1;

  button {
    color: $white;
    border: 1px solid $primary-orange;
    background-color: $primary-orange;
    text-transform: uppercase;
    font-weight: 900;
    font-family: "AutoZone Heavy";
    border-radius: 0;
    padding: 6px;
    height: 38px;
    width: 100%;
  }

  img {
    margin-right: 6px;
    height: 15px;
  }
}

.ChooseStoreModal-action-button--secondary {
  button {
    color: $black;
    border: 1px solid $black;
    background-color: $white;
  }
}

.ChooseStoreModal-input {
  margin: 0 !important;
  width: calc(50% - 16px / 2);

  fieldset {
    border: 1px solid $placeholder-color !important;
    border-radius: 0px;
  }

  label {
    font-size: 14px;
    color: $black !important;
    border-color: $placeholder-color !important;
  }

  label[data-shrink="false"] {
    transform: translate(14px, 12px);
    color: $placeholder-color !important;
  }

  input {
    height: 38px;
    padding: 0px 14px;
    font-size: 14px;
  }
}
