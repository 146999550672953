@media all and (min-width: 481px) {
  .btnWrapper .btn-primary,
  .activeOrdersWrapper .confirmSkip .btn-primary,
  .skipAction .submitButtonModal .btn-default,
  .yesButton .btn-primary,
  #completePick .confirmOk .btn-primary,
  #manualStoreDialog .manualActionsWrapper .btnSubmit .btn-default,
  #itemScanningRequiredDialog .itemScanActionsWrapper .proceedButtonItemScanModal .btn-default,
  #validationModal .validationAction .validationButtonItemScanModal .btn-default {
    color: #ffffff !important;
    background-color: #f37f00 !important;
    border-color: #f37f00 !important;
  }

  .btnWrapper .btn-primary:disabled:hover {
    color: #ffffff !important;
    background-color: #f37f00 !important;
    border-color: #f37f00 !important;
  }

  .btnWrapper .btn-primary:hover,
  .activeOrdersWrapper .confirmSkip .btn-primary:hover,
  .skipAction .submitButtonModal .btn-default:hover,
  .yesButton .btn-primary:hover,
  #completePick .confirmOk .btn-primary:hover,
  #manualStoreDialog .manualActionsWrapper .btnSubmit .btn-default:hover,
  #itemScanningRequiredDialog
    .itemScanActionsWrapper
    .proceedButtonItemScanModal
    .btn-default:hover,
  #validationModal .validationAction .validationButtonItemScanModal .btn-default:hover {
    color: #f37f00 !important;
    background-color: #ffffff !important;
    border-color: #f37f00 !important;
  }

  .activeOrdersWrapper .secondaryButton button,
  .activeOrdersWrapper .itemFoundButton .btn-primary,
  .skipAction .secondaryButton .btn-default {
    color: #f37f00 !important;
    background-color: #ffffff !important;
    border-color: #f37f00 !important;
  }

  .activeOrdersWrapper .secondaryButton button:hover,
  .activeOrdersWrapper .itemFoundButton .btn-primary:hover,
  .skipAction .secondaryButton .btn-default:hover {
    color: #ffffff !important;
    background-color: #f37f00 !important;
    border-color: #f37f00 !important;
  }

  .skipAction .noSecondaryButton .btn-default,
  #manualStoreDialog .manualActionsWrapper .btnCancel .btn-default,
  #itemScanningRequiredDialog .itemScanActionsWrapper .cancelButtonItemScanModal .btn-default,
  #validationModal .validationAction .cancelButtonItemScanModal .btn-default {
    color: #000000 !important;
    background-color: #ffffff !important;
    border-color: #000000 !important;
  }

  .skipAction .noSecondaryButton .btn-default:hover,
  #manualStoreDialog .manualActionsWrapper .btnCancel .btn-default:hover,
  #itemScanningRequiredDialog .itemScanActionsWrapper .cancelButtonItemScanModal .btn-default:hover,
  #validationModal .validationAction .cancelButtonItemScanModal .btn-default:hover {
    color: #ffffff !important;
    background-color: #000000 !important;
    border-color: #000000 !important;
  }
}
.wid-60 {
  width: 60%;
}

.wid-100 {
  width: 100%;
}

#printBtn {
  button {
    width: 100px;
  }
}

#activeListSection {
  padding-left: 4px;
  padding-right: 4px;
}

.list-group-item {
  margin-bottom: 0px;
}

#ordering {
  display: flex;
  flex-direction: column;
}

#invoiceSection {
  overflow: auto;
}

#app {
  #ordering {
    overflow-x: hidden;
    #startPicking {
      font-size: 14px;
      font-family: "Autozone Medium";
    }

    #invoiceSection {
      *[class^="col-"] {
        padding: 0px;
      }
    }

    .header {
      font-weight: bolder;
      justify-content: flex-start;
      color: $black;
      margin-top: 5px;
    }

    .checkCircle-wrapper {
      max-width: 600px;
      width: 100%;
    }

    #LeftInvoiceSection {
      width: 98%;
      margin: auto;
      border: 1px dashed #c4c4c4;
      min-height: 76px;
      height: 400px;
    }

    #RightInvoiceSection {
      border: 1px solid #c4c4c4;
      max-height: 861px;
      height: auto;
      margin-top: 31px;
      margin-right: 35px;
      overflow: auto;
    }
    .minHeightForRightSection {
      min-height: 861px;
    }
    #dashedRightInvoiceSection {
      border: 1px dashed #c4c4c4;
      min-height: 861px;
      max-height: 861px;
      margin-top: 36px;
      margin-right: 35px;
      overflow: auto;
    }

    .w-49 {
      width: 49% !important;
      text-align: right;
    }

    .refreshBlock {
      cursor: pointer;
      margin-bottom: 0.5rem;
    }

    .refreshEnabled {
      color: currentColor;
      text-decoration: underline;
    }

    .refreshDisabled {
      color: #c4c4c4;
      cursor: not-allowed;
      text-decoration: underline;
    }

    .refreshIconOrders {
      cursor: pointer;
      margin-right: 8px;
    }

    .refreshIconOrdersRotating {
      animation: rotation 1.5s infinite linear;
      -webkit-animation: rotation 1.5s infinite linear;
      -moz-animation: rotation 1.5s infinite linear;
      -o-animation: rotation 1.5s infinite linear;
      margin-right: 8px;
    }

    @keyframes rotation {
      from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
        -webkit-transform: rotate(359deg);
        -moz-transform: rotate(359deg);
        -o-transform: rotate(359deg);
      }
    }

    .overall-border {
      border-left: 0.5px solid #c4c4c4;
      border-right: 0.5px solid #c4c4c4;
    }
    .overall-border:last-child {
      border-bottom: 0.5px solid #c4c4c4;
    }
    .mar-lt-rt4 {
      margin-left: 4px;
      margin-right: 4px;
    }

    /* min -width section */
    @media all and (min-width: 481px) {
      .pad-lt-rt4 {
        padding-left: 4px;
        padding-right: 4px;
      }
      .actionWrapper {
        margin-left: 15px;
        margin-bottom: 7px;
      }
      .disabledSelectParts {
        margin-left: 12px;
        color: #c4c4c4;
      }
      .hrRule {
        margin: 4px 0px 15px 0px;
      }
      .controlSection {
        display: inline-flex;
        flex-direction: row;
        line-height: 18px;
        margin-top: 16px;
        padding-left: 0px;
        margin-left: 0px;
        *[class^="col-"] {
          padding: 0px;
        }
      }
      hr {
        margin-top: 5px;
        background-color: #9b9b9b;
      }
    }
    /* max - width section */
    @media all and (max-width: 480px) {
      #activeListSection {
        padding-left: 0px;
        padding-right: 0px;
      }

      #printBtn {
        button {
          width: 90px;
        }
      }

      #listGroup {
        border-left-width: 0px !important;
        height: auto;
        border-radius: 0px;
        overflow-y: auto !important;

        .list-group-item {
          padding-left: 16px !important;
          padding-right: 16px !important;
        }
      }
      .selectParts {
        width: 84%;
      }
      .disabledSelectParts {
        margin-left: 16px;
        color: #c4c4c4;
      }
      .hrRule {
        margin: 4px 1px 16px;
      }
      .controlSection {
        width: 100%;
        display: inline-flex;
        line-height: 18px;
        padding-left: 0px;
        margin-left: 1px;
        margin-top: 10px;
        padding-right: 0px;
        margin-right: 1px;
        *[class^="col-"] {
          padding: 0px;
        }
        .actionWrapper {
          width: 40%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
      hr {
        margin-top: 5px;
      }
    }
  }
}

.refreshRow {
  margin-bottom: 7px;
}
