#errorMessageWrapper {
  display: flex;
  align-items: center;
  float: right;

  .alertIcon {
    width: 16px;
    height: 16px;
    margin-right: 5px;

    img {
      display: block;
    }
  }

  #errorMessage {
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #d52b1e;
  }
}
