$light-grey: #f8f9fa;
$dark-grey: #a9a9a9;
$black: #231f20;
$white: #ffffff;
$primary-orange: #f37f00;
$link-orange: #f26100;
$light-orange: #ffcc99;
$placeholder-color: #c4c4c4;
$active-bg-color: #4278a0;
$inactive-bg-color: #63737e;
$light-black: #696969;
$red: #d52b1e;
$success: #008300;
